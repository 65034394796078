import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/event/event.scss"
import { getOnGoingEvents } from "../components/common/functions"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"

const IndexPage = ({ data: { allNodeContenuGenerique }, pageContext }) => {
  const isSSR = typeof window === "undefined"
  const HappeningNow = React.lazy(() =>
    import("../components/event/happeningNow")
  )
  const ListingEvents = React.lazy(() =>
    import("../components/event/listingEvents")
  )
  const HeroEvent = React.lazy(() =>
    import("../components/event/heroEvent/heroEvent")
  )

  const events = allNodeContenuGenerique ? allNodeContenuGenerique.nodes : ""
  const onGoingEvents = getOnGoingEvents(events)

  let today = new Date()

  const comingSoonEvents = events.filter(e => {
    var dateFrom = new Date(
      e.relationships.field_event_information.field_start_end_date.value
    )
    return today < dateFrom
  })

  const meaEvents = events.filter(e => {
    //return
    const dateTo = new Date(
      e.relationships.field_event_information.field_start_end_date.end_value
    )
    return e.mea === true && today < dateTo
  })

  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "page.events.seo.title" }),
    field_seo: {
      description: intl.formatMessage({
        id: "page.events.seo.meta.description",
      }),
      title: intl.formatMessage({ id: "page.events.seo.title" }),
      og_description: intl.formatMessage({
        id: "page.events.seo.ogTags.description",
      }),
      og_image: intl.formatMessage({ id: "page.events.seo.ogTags.image" }),
      og_locale: intl.locale,
      og_title: intl.formatMessage({ id: "page.events.seo.ogTags.title" }),
      og_type: intl.formatMessage({ id: "page.events.seo.ogTags.type" }),
    },
  })

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {meaEvents && <HeroEvent events={meaEvents} />}
          {onGoingEvents.length > 0 && <HappeningNow events={onGoingEvents} />}
          {comingSoonEvents.length > 0 && (
            <ListingEvents events={comingSoonEvents} />
          )}
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    allNodeContenuGenerique(
      filter: { title: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: {fields: relationships___field_event_information___field_start_end_date___value, order: ASC}
    ) {
      ...contenuGeneriqueListingFields
    }
  }
`

export default IndexPage
